import { Box, Step, StepLabel, Stepper } from '@mui/material'
import Typography from '@mui/material/Typography'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { last } from 'lodash-es'
import PropTypes from 'prop-types'
import { Else, If, Then } from 'react-if'

import FAIcon from '../../Theme/FAIcon'
import { FA_ICONS } from '../../Theme/font-awesome-icon-constants'
import { getDayMonth } from '../../utils/date-utils'
import Timer from './Timer'

const useStyles = makeStyles((theme) => ({
  stepper: {
    boxShadow: '0px 0px 0.5px 0.5px rgba(10, 8, 39, 0.05)',
    backdropFilter: 'blur(8px)',
    borderRadius: '16px',
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    minWidth: '211px',
    '& .MuiStep-root': { position: 'relative' },
    '& .MuiStepLabel-labelContainer': {
      position: 'absolute',
      left: 40,
      top: 0,
      height: 30,
      display: 'flex',
      alignItems: 'center',
    },

    '& .MuiStepConnector-line': {
      height: 40,
      width: 3,
      backgroundColor: theme.palette.background.default,
      border: 'none',
    },
  },
  defaultConnector: {
    '& .Mui-active': {
      '& .MuiStepConnector-line': {
        backgroundColor: theme.palette.primary.main,
      },
    },
    '& .Mui-completed': {
      '& .MuiStepConnector-line': {
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
  publishConnector: {
    '& .Mui-completed': {
      '& .MuiStepConnector-line': {
        backgroundColor: theme.palette.secondary.main,
      },
    },
  },
}))

const getStylesIconContainer = (step, currentStep) => {
  const background = () => {
    if (currentStep === 4) return 'secondary.main'
    if (currentStep < step) return 'background.default'
    return 'primary.main'
  }
  return {
    backgroundColor: background(),
    color: currentStep < step && 'text.tertiary',
    borderRadius: '50%',
    width: '28px',
    height: '28px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)',
  }
}

const roundIcon = (active) => (
  <Box
    width={active ? 12 : 9}
    height={active ? 12 : 9}
    borderRadius="50%"
    sx={{ backgroundColor: active ? 'text.primary' : 'text.tertiary' }}
  />
)

export const getSubmissionIcon = (activeStep) => (
  <Box sx={getStylesIconContainer(0, activeStep)}>
    <If condition={activeStep < 1}>
      <Then>{roundIcon(true)}</Then>
      <Else>
        <FAIcon
          iconClassName={FA_ICONS.CHECK}
          iconFontSize={14}
        />
      </Else>
    </If>
  </Box>
)

export const getQualificationIcon = (activeStep) => (
  <Box sx={getStylesIconContainer(1, activeStep)}>
    <If condition={activeStep > 1}>
      <Then>
        <FAIcon
          iconClassName={FA_ICONS.CHECK}
          iconFontSize={14}
        />
      </Then>
      <Else>{roundIcon(activeStep === 1)}</Else>
    </If>
  </Box>
)

export const getEvaluationIcon = (activeStep) => (
  <Box sx={getStylesIconContainer(2, activeStep)}>
    <If condition={activeStep > 2}>
      <Then>
        <FAIcon
          iconClassName={FA_ICONS.CHECK}
          iconFontSize={14}
        />
      </Then>
      <Else>{roundIcon(activeStep === 2)}</Else>
    </If>
  </Box>
)

export const getEvaluationRoundIcon = (activeStep, stepIndex) => (
  <Box sx={getStylesIconContainer(stepIndex, activeStep)}>
    <If condition={activeStep > stepIndex}>
      <Then>
        <FAIcon
          iconClassName={FA_ICONS.CHECK}
          iconFontSize={14}
        />
      </Then>
      <Else>{roundIcon(activeStep === stepIndex)}</Else>
    </If>
  </Box>
)

export const getFinalIcon = (activeStep, additionalRoundsCount) => (
  <Box sx={getStylesIconContainer(3 + additionalRoundsCount, activeStep)}>
    <FAIcon
      iconClassName={FA_ICONS.TROPHY}
      iconFontSize={14}
    />
  </Box>
)

const stepDescription = (activeStep, { value, endDate, startDate }) => {
  const date = !!startDate ? `${getDayMonth(startDate)} - ${getDayMonth(endDate)}` : getDayMonth(endDate)
  if (activeStep === 0 && value === 0) return date
  if (activeStep === value) return 'In Progress'
  if (activeStep < value) return ''
  return date
}

const CustomStepper = ({ activeStep, stepsListConfig }) => {
  const classes = useStyles()

  return (
    <Stepper
      activeStep={activeStep}
      orientation="vertical"
      className={clsx(
        classes.stepper,
        activeStep === last(stepsListConfig).value ? classes.publishConnector : classes.defaultConnector,
      )}
    >
      {stepsListConfig.map((step) => (
        <Step key={step.title}>
          <StepLabel icon={step.icon}>
            <Typography
              variant="h6"
              color="text.secondary"
            >
              {step.title}
            </Typography>
            <Typography
              variant="caption"
              color="text.tertiary"
            >
              {stepDescription(activeStep, step)}
              {step.timer !== 0 && step.timer && <Timer seconds={step.timer} />}
            </Typography>
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  )
}

CustomStepper.propTypes = {
  activeStep: PropTypes.number.isRequired,
  stepsListConfig: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      title: PropTypes.string,
      startDate: PropTypes.oneOfType([PropTypes.string, PropTypes.any]),
      endDate: PropTypes.string,
      timer: PropTypes.oneOfType([PropTypes.string, PropTypes.any]),
      icon: PropTypes.node,
    }),
  ),
}

export { CustomStepper }
