import {
  CustomStepper,
  getEvaluationIcon,
  getEvaluationRoundIcon,
  getFinalIcon,
  getQualificationIcon,
  getSubmissionIcon,
} from 'Components/_common/CustomStepper'
import { useContestVoteRoundQuery } from 'Queries/Contests'

import { CONTEST_LIFE_STATES, getContestStepperState, getContestWithRoundsStepperState } from './utils'

const ContestStepper = ({ contest }) => {
  const roundLimits = contest.round_limits
  const isEvaluationWithRounds = !!roundLimits?.length
  const { data: roundData } = useContestVoteRoundQuery(
    isEvaluationWithRounds && contest.state === CONTEST_LIFE_STATES.EVALUATION ? contest.id : '',
    CONTEST_LIFE_STATES.EVALUATION,
  )
  const activeStep = isEvaluationWithRounds
    ? getContestWithRoundsStepperState(contest.state, roundLimits, roundData?.current_round_limit)
    : getContestStepperState(contest.state)

  const stepsListConfig = [
    {
      value: 0,
      title: 'Submission',
      endDate: contest.time_add_maps_end,
      timer: contest.time_add_maps_countdown_sec,
      icon: getSubmissionIcon(activeStep),
    },
    {
      value: 1,
      title: 'Qualification',
      endDate: contest.time_qualification_end,
      icon: getQualificationIcon(activeStep),
    },
    ...(isEvaluationWithRounds
      ? roundLimits.map((limit, index) => ({
          value: 2 + index,
          title: `Top ${limit}`,
          icon: getEvaluationRoundIcon(activeStep, 2 + index),
        }))
      : [
          {
            value: 2,
            title: 'Evaluation',
            endDate: contest.time_evaluation_end,
            icon: getEvaluationIcon(activeStep),
          },
        ]),
    {
      value: isEvaluationWithRounds ? 2 + roundLimits.length : 3,
      title: 'Winners!',
      endDate: contest.time_final_end,
      icon: getFinalIcon(activeStep, isEvaluationWithRounds ? roundLimits.length - 1 : 0),
    },
  ]

  return (
    <CustomStepper
      activeStep={activeStep}
      stepsListConfig={stepsListConfig}
    />
  )
}

export default ContestStepper
