import { addMinutes, format, formatDistanceStrict, formatDistanceToNow, isBefore, parseISO, subMonths } from 'date-fns'
import enUS from 'date-fns/locale/en-US'
import { formatInTimeZone } from 'date-fns-tz'

export const getMsDate = (date) => (!!date ? format(new Date(date), 'T') : 0)

export const getFullDate = (date) => (!!date ? format(new Date(date), 'dd MMM, yyyy') : '')

export const getFullDateInDetails = (date) => (!!date ? format(new Date(date), 'MMM d, yyyy') : '')

export const getDayMonth = (date) => (!!date ? format(new Date(date), 'MMM d') : '')

export const getMonthFromMs = (date) => (!!date ? format(new Date(date), 'MMMM') : '')

export const getTimeWithTimezone = (date, timezone) => formatInTimeZone(date, timezone, 'p zzz', { locale: enUS }) // 06:00 PM EST

/**
 * @param date - 2014-3 string format example
 */
export const getMonthFromYearMonth = (date) => {
  if (!!!date) return ''
  const [y, m] = date.split('-')
  return format(new Date(y, m, 0), 'MMMM')
}

export const getTimeFromNow = (date) => (!!date ? formatDistanceToNow(new Date(date), { addSuffix: true }) : '')

export const getLeftTimeFromMinutes = (minutes) => {
  if (!!minutes) {
    const currentDate = new Date().getTime()
    const newDate = addMinutes(currentDate, minutes)
    return formatDistanceStrict(newDate, currentDate)
  }
  return ''
}

export const strictCalendarMinDate = (number) => subMonths(new Date(), number)

export const createTimeStart = () => format(new Date(), 'yyyy-MM-dd')
export const createYearTime = () => format(new Date(), 'yyyy')

export const getFormattedDate = (dateStr) => {
  if (!dateStr) return
  const date = new Date(dateStr)
  const formattedDate = format(date, 'MMM d, yyyy')
  const formattedTime = format(date, 'h:mm a')
  return `${formattedDate} at ${formattedTime}`
}

export const isCurrentDateBefore = (date) => {
  if (!date) return
  const currentDate = new Date()
  const parsedDate = parseISO(date)
  return isBefore(currentDate, parsedDate)
}

export const getDurationInMonths = (date1, date2) => {
  if (!date1 || !date2) return
  const firstDate = new Date(date1)
  const secondDate = new Date(date2)
  return formatDistanceStrict(firstDate, secondDate, {
    unit: 'month',
    roundingMethod: 'round',
  })
}

export const getDurationInDays = (date1, date2) => {
  if (!date1 || !date2) return
  const firstDate = new Date(date1)
  const secondDate = new Date(date2)
  return formatDistanceStrict(firstDate, secondDate, {
    unit: 'day',
    roundingMethod: 'round',
  })
}
